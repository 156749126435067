/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { TableBodyRow, TableBodyCell, Swatch, TrashIcon, TooltipOnHover, PencilIcon, ConfirmDialog, flex } from '@bonitour/components'
import { defaultRowHeight, defaultCellPadding, clickable, marginRight } from 'assets/styles/global'
import { identity } from '@bonitour/common-functions'
import { useState } from 'react'

const iconSize = css`
  font-size: 23px;
`

export function ActivityVacancyRow ({
  activityVacancy,
  onUpdate: emitUpdateEvent = identity,
  onDelete: emitDeleteEvent = identity,
  onExceptionalDayUpdate: emitExceptionalDayUpdateEvent = identity,
  onExceptionalDayDelete: emitExceptionalDayDeleteEvent = identity,
  ...other
}) {
  const { id, color, name, vacancyQuantity, dailyLimit, periodQuantity, schedulesQuantity, isExceptionalDay, exceptionalDate } = activityVacancy
  const [isConfirmActionVisible, setConfirmActionVisible] = useState(false)
  const toggleConfirmActionVisible = () => setConfirmActionVisible(true)
  const toggleConfirmActionHidden = () => setConfirmActionVisible(false)

  const onDeleteVacancy = () => {
    isExceptionalDay ? emitExceptionalDayDeleteEvent(id) : emitDeleteEvent(id)
    toggleConfirmActionHidden()
  }

  return (
    <>
      <ConfirmDialog
        title={`Exclusão de ${isExceptionalDay ? 'dia excepcional' : 'vagas'}`}
        message={`${isExceptionalDay ? 'Esse dia excepcional será excluído' : 'Essa vaga será excluída'} permanentemente. Deseja continuar?`}
        isVisible={isConfirmActionVisible}
        successCallback={onDeleteVacancy}
        cancelCallback={toggleConfirmActionHidden}
      />

      <TableBodyRow css={defaultRowHeight} {...other}>
        <TableBodyCell css={defaultCellPadding}>
          <Swatch size={20} color={color} />
        </TableBodyCell>
        <TableBodyCell css={defaultCellPadding}>{name}</TableBodyCell>
        <TableBodyCell css={defaultCellPadding}>{dailyLimit}</TableBodyCell>
        <TableBodyCell css={defaultCellPadding}>{vacancyQuantity}</TableBodyCell>
        <TableBodyCell css={defaultCellPadding}>{periodQuantity}</TableBodyCell>
        <TableBodyCell css={defaultCellPadding}>{schedulesQuantity}</TableBodyCell>
        <TableBodyCell css={defaultCellPadding} fixed>
          <div css={flex}>
            <TooltipOnHover text={`Editar ${isExceptionalDay ? 'dia excepcional' : 'vaga'}`}>
              <PencilIcon css={[iconSize, clickable, marginRight(10)]} onClick={isExceptionalDay ? emitExceptionalDayUpdateEvent(exceptionalDate) : emitUpdateEvent(id)} />
            </TooltipOnHover>
            <TooltipOnHover text={`Excluir ${isExceptionalDay ? 'dia excepcional' : 'vaga'}`}>
              <TrashIcon css={[iconSize, clickable]} onClick={toggleConfirmActionVisible} />
            </TooltipOnHover>
          </div>
        </TableBodyCell>
      </TableBodyRow>
    </>
  )
}

import { orbCore } from 'services/Orb/Service'
import { CombinedExperienceModel } from './Model'
import { CombinedExperienceParser } from './Parser'

const { combinedExperienceDomain, sellableCombinedExperienceDomain } = orbCore

export const CombinedExperienceService = {
  create: async (data) => combinedExperienceDomain
    .create(CombinedExperienceModel.create(data))
    .then(CombinedExperienceParser.extractExperience),
  update: async (id, data) => combinedExperienceDomain
    .update(id, CombinedExperienceModel.update(data)),
  get: async (id) => combinedExperienceDomain
    .get(id)
    .then(CombinedExperienceParser.extractExperience),
  getSellable: async (id) => sellableCombinedExperienceDomain
    .getSellable(id)
    .then(CombinedExperienceParser.extractExperience),
  list: async (data) => combinedExperienceDomain
    .list(CombinedExperienceModel.list(data))
    .then(CombinedExperienceParser.allExperiences),
  listSellable: async (data) => sellableCombinedExperienceDomain
    .listSellable(CombinedExperienceModel.list(data))
    .then(CombinedExperienceParser.allExperiences)
}

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { colors, H3, InputWithSuffix, Select, ToggleInputGroup, TooltipOnHover, TrashIcon } from '@bonitour/components'

import { AddButton } from 'components/AddButton'
import { marginBottom, marginTop } from 'assets/styles/global'

import { useReducer, useCallback, useMemo } from 'react'
import { useActivity } from 'contexts/Activity'
import { identity } from '@bonitour/common-functions'
import { useFeatureFlag } from 'contexts/Feature'

const marginTop20 = marginTop(20)
const marginBottom10 = marginBottom(10)

const customCommissionContainer = css`
  --addbtn-clr: ${colors.primaryVariant};
`

export const customComissionsList = css`
  display: grid;

  grid-template-columns: 1fr;
  gap: 8px;
`

const customComissionItem = css`
  display: grid;
  grid-template-columns: 380px auto auto 1fr;
  gap: 16px;
  align-items: center;
  justify-content: flex-start;
  padding: 8px;

  border: 1px dashed ${colors.gray9};
  border-radius: 16px;

  .item__input {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100%;
  }

  .delete__container {
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    button {
      background: none;
      border: 0;
      padding: 8px;
      font-size: 18px;
      color: ${colors.gray3};
      cursor: pointer;
      margin-left: auto;
    }
  }

  .input__label {
    font-size: 13px;
    color: ${colors.gray2};
    margin-bottom: 4px;
    font-weight: 600;
  }

  .select__service {
    input::placeholder {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`

const width100prc = css`
  width: 100%;
`

const hiddenTooltip = css`
  ${width100prc}
  .tooltip-on-hover {
    display: none !important;
  }
`

const tooltipStyle = css`
  ${width100prc}
  .tooltip-on-hover {
    margin-bottom: 0.75rem;
    width: calc(100% - 2rem);
    line-height: 1.15rem;
    word-break: break-word;
  }
`

const baseCustomComission = {
  service: '',
  commission: 0,
  netFeeEnabled: false,
  errors: {
    service: false,
    commission: false
  }
}

const customComissionReducer = ({
  baseComission = 0,
  isNetFeeDefault = false,
  onUpdate = identity
}) => (state, action) => {
  let newState = state

  const getSafeComission = (val) => {
    const numericVal = Number(val)

    if (!val) return 0
    if (val < 0) return 0
    if (numericVal > 100) return 100

    return numericVal
  }

  const safeUpdate = (field, currVal, newVal) => {
    if (field === 'commission') {
      return getSafeComission(newVal)
    }

    if (field === 'netFeeEnabled') {
      return !currVal
    }

    return newVal
  }

  switch (action.type) {
  case 'add':
    newState = [
      ...state,
      {
        ...baseCustomComission,
        netFeeEnabled: isNetFeeDefault,
        commission: baseComission,
        errors: {
          service: true,
          commission: !Number(baseComission) || Number(baseComission) < 0
        }
      }
    ]
    break
  case 'remove':
    newState = state.filter((_, index) => index !== action.index)
    break
  case 'update':
    newState = state.map((customComission, index) => {
      if (index === action.index) {
        return {
          ...customComission,
          [action.field]: safeUpdate(
            action.field,
            customComission[action.field],
            action.value
          ),
          errors: {
            service: action?.field === 'service'
              ? !action.value
              : customComission?.errors?.service || false,
            commission: action?.field === 'commission'
              ? !Number(action.value) || Number(action.value) < 0
              : customComission?.errors?.commission || false
          }
        }
      }
      return customComission
    })
    break
  default:
    break
  }

  onUpdate(newState)
  return newState
}

export const CustomComissions = ({
  isNetFeeDefault = false,
  baseComission = 20,
  onChange = identity,
  initialData = [],
  isDisabled,
  isGroupEditable
}) => {
  const [isNetValueEnabled] = useFeatureFlag('orb-net-value')
  const [customComissions, dispatchCustomComissons] = useReducer(
    customComissionReducer({
      baseComission,
      isNetFeeDefault,
      onUpdate: onChange
    }),
    initialData
  )

  const { companyActivities, partnerActivities } = useActivity()

  const handleAddCustomComission = useCallback(() => {
    dispatchCustomComissons({ type: 'add' })
  }, [])

  const handleRemoveCustomComission = useCallback(index => {
    dispatchCustomComissons({ type: 'remove', index })
  }, [])

  const handleUpdateServiceIdCustomComission = useCallback((index, value) => {
    dispatchCustomComissons({
      type: 'update',
      index,
      field: 'service',
      value
    })
  }, [])

  const handleUpdateCommissionCustomComission = useCallback((index, value) => {
    dispatchCustomComissons({
      type: 'update',
      index,
      field: 'commission',
      value
    })
  }, [])

  const handleToggleeNetFeeEnabledCustomComission = useCallback((index) => {
    dispatchCustomComissons({
      type: 'update',
      index,
      field: 'netFeeEnabled'
    })
  }, [])

  const companyServicesOptions = useMemo(() => {
    return companyActivities.concat(partnerActivities).map(activity => ({
      value: activity.id,
      label: activity.name
    }))
  }, [companyActivities, partnerActivities])

  const activitiesOptions = useMemo(() => {
    const preSelected = initialData
      .map(({ service: serviceId, titleName }) => ({
        value: serviceId,
        label: titleName
      }))
    return [...companyServicesOptions, ...preSelected]
  }, [companyServicesOptions, initialData])

  const getServiceLabel = useCallback((serviceId) => activitiesOptions.find(({ value: serviceValue }) => serviceValue === serviceId)?.label, [activitiesOptions])

  const getCanShowTooltip = useCallback((serviceId) => {
    const serviceLabel = getServiceLabel(serviceId)
    return serviceLabel && serviceLabel.length > 36
  }, [getServiceLabel])

  return (
    <section css={customCommissionContainer}>
      <H3 css={[marginTop20, marginBottom10]}>
        Comissionamento personalizado
      </H3>

      <ul css={customComissionsList}>
        {customComissions.map((customComission, index) => (
          <li key={index} css={customComissionItem}>
            <div className='item__input'>
              <span className='input__label'>Serviço</span>
              <TooltipOnHover text={getServiceLabel(customComission.service)} customCss={[getCanShowTooltip(customComission.service) ? tooltipStyle : hiddenTooltip]}>
                <Select
                  options={activitiesOptions}
                  value={customComission.service}
                  customCss={[width100prc]}
                  onChange={value => handleUpdateServiceIdCustomComission(index, value)}
                  error={customComission?.errors?.service}
                  disabled={isDisabled}
                  className='select__service'
                />
              </TooltipOnHover>
            </div>

            <div className='item__input'>
              <span className='input__label'>Porcentagem</span>
              <InputWithSuffix
                min='0'
                type='number'
                value={customComission.commission}
                onChange={(value) => handleUpdateCommissionCustomComission(index, value)}
                onBlur={(value) => handleUpdateCommissionCustomComission(index, value)}
                error={customComission?.errors?.commission}
                disabled={isDisabled || !isGroupEditable}
              >%
              </InputWithSuffix>
            </div>

            {isNetValueEnabled
              ? (
                <div className='item__input'>
                  <span className='input__label'>Tarifa Net</span>
                  <ToggleInputGroup
                    onChange={() => handleToggleeNetFeeEnabledCustomComission(index)}
                    checked={customComission.netFeeEnabled}
                    disabled={isDisabled}
                  />
                </div>
              )
              : <span>&nbsp;</span>}

            <div className='item__input delete__container'>
              <button
                type='button'
                onClick={() => handleRemoveCustomComission(index)}
                disabled={isDisabled || !isGroupEditable}
              >
                <TrashIcon />
              </button>
            </div>
          </li>
        ))}
      </ul>

      <AddButton
        onClick={handleAddCustomComission}
        disabled={isDisabled || (!isNetValueEnabled && !isGroupEditable)}
      >
            Nova Comissão Personalizada
      </AddButton>

    </section>
  )
}

import { useToast } from '@bonitour/components'
import { useActivity } from 'contexts/Activity'
import { useBack } from 'hooks/useBack'
import { usePermissions } from 'hooks/usePermissions'
import { useCallback, useState } from 'react'
import { CombinedExperienceService } from 'services/combinedExperience/Service'

const INITIAL_REQUESTS_DATA = {
  isLoading: {
    creation: false
  },
  responseData: {
    creation: null
  }
}

const errorList = {
  'experiences::experience::title::taken': 'Já existe uma experiência combinada com o mesmo título.'
}

export const useCombinedExperience = () => {
  const {
    add: addToast
  } = useToast()

  const {
    handlePermission
  } = usePermissions()

  const {
    onSafeBack
  } = useBack()

  const {
    setId, updateList
  } = useActivity()

  const [requestsData, setRequestsData] = useState(INITIAL_REQUESTS_DATA)

  const changeIsLoading = useCallback((field = '', value = false) => {
    if (!field) return

    setRequestsData(prev => ({
      ...prev,
      isLoading: {
        ...prev.isLoading,
        [field]: value
      }
    }))
  }, [])

  const fillResponse = useCallback((field = '') => (value = null) => {
    if (!field) return

    setRequestsData(prev => ({
      ...prev,
      responseData: {
        ...prev.responseData,
        [field]: value
      }
    }))
  }, [])

  /**
   * @param {string} action; Label of the action that is being performed
   * @param {string} indefiniteArticle; Indefinite article of the action that is being performed
   */
  const showError = useCallback((action = '', indefiniteArticle = '') => (error) => {
    const hasPermission = handlePermission(error)
    if (!hasPermission) {
      return addToast(`Você não tem permissão para ${action} ${indefiniteArticle} experiência combinada`)
    }

    const { data: { errors_msg: errorsMsg = [] } } = error

    let isToasted = false

    errorsMsg.forEach(error => {
      if (errorList[error]) {
        addToast(errorList[error], 'error')
        isToasted = true
      }
    })

    if (isToasted) return

    addToast(`Houve um erro na ${action} ${indefiniteArticle} experiência combinada`, 'error')
  }, [addToast, handlePermission])

  /**
   * @param {string} previousPath; The route path for back when is success
   */
  const create = useCallback((previousPath = '') => (payload = {}) => {
    if (requestsData.isLoading.creation) return
    changeIsLoading('creation', true)
    return CombinedExperienceService.create(payload)
      .then(data => {
        setId(data.id)
        updateList(true)
        return data
      })
      .then(fillResponse('creation'))
      .then(() => onSafeBack(previousPath))
      .then(() => addToast('Experiência combinada criada com sucesso', 'success'))
      .catch(showError('criação', 'de uma'))
      .finally(() => changeIsLoading('creation', false))
  }, [requestsData.isLoading.creation, changeIsLoading, fillResponse, showError, setId, updateList, onSafeBack, addToast])

  return {
    isLoadingCreation: requestsData.isLoading.creation,
    isSuccessCreation: Boolean(requestsData.responseData.creation),
    create
  }
}

import { css } from '@emotion/core'
import { BREAK_POINTS, colors } from '@bonitour/components'

export const partnerTitle = css`
  font-size: 12px;
  color: ${colors.gray4};
  font-weight: bolder;
`

export const partnerSubtitle = css`
  font-size: 11px;
  color: ${colors.gray6};
`

export const pointer = css`
  cursor: pointer;
`

export const activityCard = css`
  display: flex;
  box-sizing: border-box;
  color: ${colors.gray3};
  margin: 10px 0;
  align-items: center;
  padding: 0px 5px;
  position: relative;

  &.active{
    background-color: ${colors.gray12};
  }
`

export const extendedActivityCard = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
  justify-content: space-evenly;
  padding: 20px 0;
  position: relative;
`

export const smallCardTitleContainer = css`
  max-width: calc(100% - 4.25rem);
`

export const activitiesPageCardImage = css`
  width: 79px;
  height: 79px;
  margin: 0;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
`

export const activitiesPageCardSubtitle = css`
  color: ${colors.gray6};
  height: 21px;
`

export const activitiesPageCardCompanyInfo = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  padding: 0;
  text-align: center;
`

export const seeMoreIconPosition = css`
  transform: translate3d(5px,1px,10px);
  color: ${colors.gray4};
`

export const link = css`
  color: ${colors.gray4};
`

export const activityName = css`
  color: ${colors.gray3};  
`

export const tooltipContent = css`
  .tooltip-on-hover {
    word-break: break-word;
  }
`

export const title = css`
  font-size: 14px;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;  
  white-space: nowrap;
  line-height: 1.375;
  max-width: 100%;

  @media (max-width: ${BREAK_POINTS.bigPhone}) {
    max-width: 95px;
  }
`

export const titleMaxWidth = css`
  max-width: 10rem;
`

export const labelContainer = css`
  display: flex;
  align-items: center;

  @media (max-width: ${BREAK_POINTS.bigPhone}) {
    font-size: 0.75rem;
  }
`

export const titleTooltipContainer = css`
  justify-content: start;
`

export const serviceTypeLabel = css`
  position: absolute;
  top: 0.125rem;
  right: 0.125rem;
  font-size: 1.375rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  color: ${colors.gray2};
  width: 1.5rem;
  overflow: visible;

  > div {
    background-color: ${colors.white};
    border-radius: var(--round-element-radius);
    padding: 0.25rem;
  }

  p {
    font-size: 0.65rem;
    color: ${colors.gray4};
    font-weight: 600;
    background-color: ${colors.gray14};
    padding: 0.25rem;
    border-radius: 0.375rem;
    border: 1px solid ${colors.gray10};
    transform: scale(0);
    transform-origin: center right;
    transition: transform 0.2s ease;
    text-align: center;
    pointer-events: none;
  }

  &:hover p {
    transform: scale(1);
  }
`

export const serviceTypeLabelTiny = css`
  ${serviceTypeLabel}
  font-size: 1rem;
  right: 0.25rem;
  top: 50%;
  transform: translateY(-50%);
`

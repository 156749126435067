/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { TableContainer, TableHeader, TableHeaderRow, TableHeaderCell, TableBody, scrollBar } from '@bonitour/components'
import { width } from 'assets/styles/global'
import { ActivityVacancyRow } from 'containers/ActivityVacancyTable/ActivityVacancyRow'
import { identity } from '@bonitour/common-functions'

const width10 = width(10)

const tableContainer = css`
  overflow: auto;
  max-height: 480px;
  ${scrollBar(5)};
`

export const ActivityVacancyList = ({
  activityVacancyList = [],
  onDelete: emitDeleteEvent = identity,
  onCreate: emitCreateEvent = identity,
  onUpdate: emitUpdateEvent = identity,
  onExceptionalDayUpdate: emitExceptionalDayUpdateEvent = identity,
  onExceptionalDayDelete: emitExceptionalDayDeleteEvent = identity
}) => {
  const emitCopyEvent = activityVacancyItem => event => {
    event.stopPropagation()
    emitCreateEvent(activityVacancyItem)
  }

  return (
    <div css={tableContainer}>
      <TableContainer>
        <TableHeader>
          <TableHeaderRow>
            <TableHeaderCell>Cor</TableHeaderCell>
            <TableHeaderCell>Nome</TableHeaderCell>
            <TableHeaderCell>Limite Diário</TableHeaderCell>
            <TableHeaderCell>Total de Vagas</TableHeaderCell>
            <TableHeaderCell>Período</TableHeaderCell>
            <TableHeaderCell>Horários</TableHeaderCell>
            <TableHeaderCell css={width10} fixed>Opções</TableHeaderCell>
          </TableHeaderRow>
        </TableHeader>
        <TableBody>
          {activityVacancyList.map((activityVacancyItem, index) => (
            <ActivityVacancyRow
              key={`activity-vacancy-item-${index}`}
              activityVacancy={activityVacancyItem}
              onUpdate={emitUpdateEvent}
              onDelete={emitDeleteEvent}
              onExceptionalDayUpdate={emitExceptionalDayUpdateEvent}
              onExceptionalDayDelete={emitExceptionalDayDeleteEvent}
              onCopy={emitCopyEvent(activityVacancyItem)}
            />
          ))}
        </TableBody>
      </TableContainer>
    </div>
  )
}

import React, { createContext, useContext, useMemo, useCallback, useEffect, useState } from 'react'
import { ActivityStore } from 'core/store/Activity'
import { useSimpleStorage } from 'hooks/useSimpleStorage'
import { ActivityService } from 'services/Activity/Service'
import { useParams } from 'react-router-dom'
import { useCompany } from './Company'
import { ReservationMapService } from 'services/ReservationMap/Service'
import { formatISOTime } from 'utils/time'
import { signal } from '@preact/signals-core'
import { useCachedPromise } from 'hooks/useCachedPromise'
import { CombinedExperienceService } from 'services/combinedExperience/Service'
import { useToast } from '@bonitour/components'
import { OFFLINE_EXPERIENCE_TYPE } from 'constants/activityTypes'

export const SellableListContext = createContext({})

export const useSellableList = () => useContext(SellableListContext)

export const SellableListProvider = ({ children }) => {
  const enabledActivities = true
  const [sellableList, setSellableList] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  const { invalidateCache, resolvePromiseWithCache } = useCachedPromise({ domain: 'activityCache' })
  const { invalidateCache: invalidateExperiencesCache } = useCachedPromise({ domain: 'experiencesCache' })

  const fetchSellableList = useCallback(
    ({ type = false } = {}) => resolvePromiseWithCache(ActivityService.list, 'list', enabledActivities)
      .then(list => list.filter(({ type: activityType }) => !type || activityType === type)),
    [resolvePromiseWithCache, enabledActivities]
  )

  const updateList = useCallback((mustInvalidateCache = false) => {
    if (mustInvalidateCache) {
      invalidateCache()
      invalidateExperiencesCache()
    }
    setIsLoading(true)
    fetchSellableList().then(setSellableList).finally(() => setIsLoading(false))
  }, [invalidateCache, invalidateExperiencesCache, fetchSellableList])

  useEffect(() => {
    updateList()
  }, [updateList])

  const contextData = useMemo(
    () => ({
      updateList,
      sellableList,
      loading: isLoading,
      fetchSellableList,
      invalidateCache
    }),
    [fetchSellableList, invalidateCache, isLoading, sellableList, updateList]
  )

  return <SellableListContext.Provider value={contextData}>{children}</SellableListContext.Provider>
}

export const ActivityContext = createContext({})

export const useActivity = () => {
  const { activityId } = useParams()
  const methods = useContext(ActivityContext)
  const { id, setId } = methods

  useEffect(() => {
    if (activityId && activityId !== id) {
      setId(activityId)
    }
  }, [activityId, setId, id])

  return methods
}

const wasActivityPreviouslyRequested = signal(false)

const sortActivities = (activities) =>
  activities.sort((a, b) => a.companyName.localeCompare(b.companyName) || a.name.localeCompare(b.name))

const isOffline = ({ type }) => type === OFFLINE_EXPERIENCE_TYPE

const ActivityProviderBase = ({ children }) => {
  const { id: companyId } = useCompany()
  const [id, setId] = useSimpleStorage(ActivityStore.getId, ActivityStore.setId)
  const { sellableList, updateList, isLoading } = useSellableList()
  const [partners, setPartners] = useState([])
  const [activity, setActivity] = useSimpleStorage(ActivityStore.getActivity, ActivityStore.setActivity)

  const companyActivities = useMemo(
    () => sortActivities(sellableList).filter(({ isPartner, type }) => !isOffline({ type }) && !isPartner),
    [sellableList]
  )
  const partnerActivities = useMemo(
    () => sortActivities(sellableList).filter(({ isPartner, type }) => !isOffline({ type }) && isPartner),
    [sellableList]
  )
  const unparsedActivities = useMemo(
    () => companyActivities.concat(partnerActivities),
    [companyActivities, partnerActivities]
  )

  const companiesOptions = useMemo(
    () =>
      partners.map(({ companyId, companyName }) => ({
        value: companyId,
        label: companyName
      })),
    [partners]
  )

  const parseActivities = useCallback(
    (selectedCompanyId) => {
      if (!selectedCompanyId) return []

      const activitiesFiltered = unparsedActivities.filter(({ companyId }) => companyId === selectedCompanyId)

      return activitiesFiltered.map(({ id, name, companyName }) => ({
        value: id,
        label: `${name} - ${companyName}`
      }))
    },
    [unparsedActivities]
  )

  const getActivityById = useCallback((searchId) => sellableList.find(({ id: activityId }) => activityId === searchId), [sellableList])

  const getActivitiesByPartnerId = useCallback(
    (searchId) => sellableList.filter(({ companyId }) => companyId === searchId),
    [sellableList]
  )

  const { add: addToast } = useToast()

  useEffect(() => {
    const selectedActivity = sellableList.find(({ id: activityId }) => activityId === id)
    if (selectedActivity) {
      setActivity(selectedActivity)
    } else {
      if (!id) {
        setActivity({})
        return
      }
      if (!wasActivityPreviouslyRequested.value) {
        wasActivityPreviouslyRequested.value = 1
        updateList()
      } else {
        if (wasActivityPreviouslyRequested.value === 2) {
          return
        }
        wasActivityPreviouslyRequested.value = 2
        setActivity({})
        CombinedExperienceService.getSellable(id)
          .then(experience => ({
            ...experience,
            isPartner: false // TODO check if experience.companyId is not the same as companyId
          }))
          .then(setActivity)
          .catch(() => {
            updateList(true)
            return addToast('Experiencia não encontrada', 'warning')
          })
      }
    }
  }, [id, sellableList, updateList, setActivity, addToast, companyId, setId])

  const fetchPartners = useCallback(() => {
    return ActivityService.getPartners(companyId).then(setPartners)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    fetchPartners()
  }, [fetchPartners])

  const fetchHourly = useCallback((serviceId, date, companyId) => {
    const DateFormatted = formatISOTime(date.toISOString(), 'YYYY-MM-DD HH:mm:ss')
    return ReservationMapService.getHourlyOptions({
      serviceId,
      companyId,
      startDate: DateFormatted,
      endDate: DateFormatted
    })
  }, [])

  const updateId = useCallback((newId) => {
    setId(newId)
    wasActivityPreviouslyRequested.value = false
  }, [setId])

  const contextData = useMemo(
    () => ({
      getActivityById,
      getActivitiesByPartnerId,
      fetchPartners,
      partners,
      id,
      list: sellableList,
      setId: updateId,
      activity,
      companyActivities,
      partnerActivities,
      loading: isLoading,
      updateList,
      unparsedActivities,
      parseActivities,
      fetchHourly,
      companiesOptions
    }),
    [getActivityById, getActivitiesByPartnerId, fetchPartners, partners, id, sellableList, updateId, activity, companyActivities, partnerActivities, isLoading, updateList, unparsedActivities, parseActivities, fetchHourly, companiesOptions]
  )

  return <ActivityContext.Provider value={contextData}>{children}</ActivityContext.Provider>
}

export const ActivityProvider = ({ children }) => (
  <SellableListProvider>
    <ActivityProviderBase>{children}</ActivityProviderBase>
  </SellableListProvider>
)

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */

import { jsx } from '@emotion/core'
import { useCallback, useMemo } from 'react'
import { identity } from '@bonitour/common-functions'
import { AddTicketIcon, Card, CheckboxInputGroup, EditInfoIcon, Fab, flexColumn, HourglassIcon, TrashIcon, WarningIcon } from '@bonitour/components'
import { SwatchImage } from 'components/SwatchImage'
import { clickable, hidden } from 'assets/styles/global'
import { deleteButtonStyle, serviceCardStyle, serviceProfileStyle, addServiceBtnStyle, disabledEffect, addServiceBtnLoadingStyle, serviceCardSkeleton, serviceTitleAndCompanyStyle, editButtonStyle, serviceCheckboxStyle } from './CombinedExperienceForm.styles'
import { ExperienceSelectorModal } from 'containers/Reservations/ReservationList/ExperienceSelectorModal'
import { COMBINED_EXPERIENCE_TYPE, OFFLINE_EXPERIENCE_TYPE } from 'constants/activityTypes'
import { ActivityCard } from 'containers/Activity/ActivityCard'

const SERVICE_INITIAL_VALUE = {
  id: ''
}

export const CombinedExperienceFormServiceCard = ({
  onExperienceSubmit = identity,
  onExperienceRemove = identity,
  formValues = SERVICE_INITIAL_VALUE,
  isLoadingServiceOptions = false,
  isEmptyCard = false,
  isLoading = false,
  isSkeleton = false,
  error = ''
}) => {
  const handleFieldChange = useCallback((fieldName) => (value) => {
    onExperienceSubmit({ [fieldName]: value })
  }, [onExperienceSubmit])

  const serviceError = useMemo(() => !isLoadingServiceOptions && error, [isLoadingServiceOptions, error])

  return <Card
    css={isSkeleton ? serviceCardSkeleton : serviceCardStyle}
    className={[isEmptyCard && 'is-empty', error && 'danger-border'].join(' ')}
  >
    <div
      css={[isLoading ? disabledEffect : clickable, isSkeleton && hidden, deleteButtonStyle]}
      onClick={() => onExperienceRemove(formValues.id)}
    >
      <span className='icon-label'>Remover</span>
      <TrashIcon className='icon'/>
    </div>
    <div css={serviceProfileStyle}>
      <SwatchImage alternativeText={formValues.name} image={formValues.image} size={83}/>
      <section css={flexColumn}>
        <ActivityCard
          customCss={[serviceTitleAndCompanyStyle]}
          activity={formValues}
          hideActionLabel
          hasTitleMaxWidth={false}
          disableLink
          hideImage
          showNameTooltip
          hideTypeLabel
        />
        <CheckboxInputGroup
          customCss={[serviceCheckboxStyle]}
          id={`optional-${formValues.id}`}
          disabled={isLoading}
          checked={formValues.isOptional}
          onChange={() => handleFieldChange('isOptional')(!formValues.isOptional)}
          error={serviceError}
        >
          <span css={[serviceCheckboxStyle]}>
            Serviço opcional
          </span>
        </CheckboxInputGroup>
        <ExperienceSelectorModal
          customCss={[editButtonStyle]}
          onExperienceSelected={handleFieldChange('id')}
          fromCurrentCompany
          disabledTypes={[OFFLINE_EXPERIENCE_TYPE, COMBINED_EXPERIENCE_TYPE]}
        >
          <span className='icon-label'>Alterar</span>
          <EditInfoIcon className='icon'/>
        </ExperienceSelectorModal>
      </section>
    </div>
  </Card>
}

export const AddServiceButton = ({
  onAddService = identity,
  isEnabledOnBottom = false,
  isRow = false,
  isEmptyExperiencesOptions = false,
  isLoading = false,
  isLoadingServiceOptions = false
}) => {
  return (
    <ExperienceSelectorModal
      onExperienceSelected={onAddService}
      fromCurrentCompany
      disabledTypes={[OFFLINE_EXPERIENCE_TYPE, COMBINED_EXPERIENCE_TYPE]}
    >
      <Card
        css={isLoadingServiceOptions ? addServiceBtnLoadingStyle : addServiceBtnStyle}
        className={[
          isEnabledOnBottom && 'on__bottom',
          isRow && 'row',
          (isEmptyExperiencesOptions && !isRow) && 'is__read_only_not_as_row',
          (isEmptyExperiencesOptions && isRow) && 'is__read_only_as_row',
          (!isEmptyExperiencesOptions && isLoading && !isRow) && 'disabled__not_as_row',
          (!isEmptyExperiencesOptions && isLoading && isRow) && 'disabled__as_row']
          .join(' ')}
      >
        <div>
          <Fab id='add-service-btn' disabled={!isEmptyExperiencesOptions && (isLoading && !isLoadingServiceOptions)}>
            {(isEmptyExperiencesOptions && !isLoadingServiceOptions)
              ? <WarningIcon />
              : isLoadingServiceOptions
                ? <HourglassIcon/>
                : <AddTicketIcon/>}
          </Fab>
          <span>
            {(isEmptyExperiencesOptions && !isRow && !isLoadingServiceOptions)
              ? 'Sem mais experiências para adicionar'
              : (isEmptyExperiencesOptions && isRow && !isLoadingServiceOptions)
                ? 'Não há experiências disponíveis para adicionar'
                : isLoadingServiceOptions
                  ? 'Carregando a lista de experiências'
                  : 'Adicionar experiência'}
          </span>
        </div>
      </Card>
    </ExperienceSelectorModal>
  )
}

import { COMBINED_EXPERIENCE_TYPE } from 'constants/activityTypes'

const service = ({
  id,
  experience_id: experienceId,
  service_id: serviceId,
  optional: isOptional,
  company_id: companyId
}) => ({
  id,
  experienceId,
  serviceId,
  isOptional,
  companyId
})

export const CombinedExperienceParser = {
  pagination: ({
    total_entries,
    current_page,
    total_pages
  }) => ({
    totalEntries: total_entries,
    currentPage: current_page,
    totalPages: total_pages
  }),
  experience: ({
    id,
    company_id: companyId,
    title,
    subtitle,
    description,
    enabled: isEnabled,
    services_has_to_be_on_same_day: isSameDayServices,
    experiences_services: experiences = [],
    vendor_id: vendorId,
    vendor_name: vendorName
  }) => ({
    id,
    companyId: companyId || vendorId,
    companyName: vendorName,
    name: title,
    subtitle,
    description: description || null,
    isEnabled,
    isSameDayServices,
    type: COMBINED_EXPERIENCE_TYPE,
    experiences: experiences.map(service)
  }),
  allExperiences: ({
    experiences = [],
    meta
  }) => ({
    experiences: experiences.map(CombinedExperienceParser.experience),
    meta: CombinedExperienceParser.pagination(meta)
  }),
  extractExperience: ({
    experience = {}
  }) => CombinedExperienceParser.experience(experience)
}
